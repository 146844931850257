.navbar-container {
    width: 100%;
    height: 58px;
    background: linear-gradient(90deg, #1A5DA1, #00ccff, #2575FC, #1e7fc0);
    background-size: 400% 400%; /* Create a shifting effect */
    animation: gradientAnimation 15s ease infinite; /* Animate the gradient */
    margin-top: 8px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Add position relative to manage z-index of child elements */
    z-index: 11; /* Ensure the navbar stays on top */
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.navbar-sub-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.burger-menu {
    display: none;
    align-items: center;
    cursor: pointer;
    z-index: 9999; /* Ensure the burger menu stays on top */
    position: absolute;
    left: 20px; /* Align to the left */
    color: white;
}

.navbar ul {
    display: flex;
    gap: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.anim-nav a {
    padding: 0 .5em;
    position: relative;
    text-decoration: none;
    display: inline-block;
}

.anim-nav a:before {
    position: absolute;
    content: '';
    transition: all 0.35s ease;
    opacity: 0;
    left: 15%;
    right: 15%;
    top: 0;
    bottom: 0;
    border-left: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
}

.anim-nav a:hover,
.anim-nav .current a {
    color: #ffffff;
}

.anim-nav a:hover:before,
.anim-nav .current a:before {
    opacity: 1;
    left: 0;
    right: 0;
}

/* Responsive Design */

/* Large screens */
@media (min-width: 1200px) {
    .navbar-container {
        padding: 0px 320px;
    }

    .navbar ul {
        gap: 54px;
    }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1199px) {
    .navbar-container {
        padding: 0px 40px;
    }

    .navbar ul {
        gap: 40px;
    }
}

/* Small screens */
@media (min-width: 576px) and (max-width: 767px) {
    .burger-menu {
        display: flex;
    }

    .navbar ul {
        display: none; /* Hide navbar items on small screens */
    }

    .navbar ul.active {
        display: flex;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 50%; /* Set width to half */
        background-color: white; /* Set background to white */
        border: 1px solid #1A5DA1; /* Add a border */
        border-radius: 10px; /* Rounded corners */
        font-size: 18px;
        padding: 10px;
        color: #1A5DA1;
        position: absolute;
        top: 58px; /* Position below the navbar */
        left: 20px; /* Align to the left */
        z-index: 9998; /* Ensure the menu stays on top */
    }

    .mobile-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobile-menu ul li {
        padding: 10px 0;
    }

    .mobile-menu ul li a {
        color: #1A5DA1;
        text-decoration: none;
    }

    .mobile-menu ul li a:hover {
        color: #A6C8EB; /* Light blue color on hover */
    }
}

/* Mobile screens */
@media (max-width: 575px) {
    .burger-menu {
        display: flex;
    }

    .navbar ul {
        display: none; /* Hide navbar items on mobile screens */
    }

    .navbar ul.active {
        display: flex;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 50%; /* Set width to half */
        background-color: white; /* Set background to white */
        border: 1px solid #1A5DA1; /* Add a border */
        border-radius: 10px; /* Rounded corners */
        font-size: 18px;
        padding: 10px;
        color: #1A5DA1;
        position: absolute;
        top: 58px; /* Position below the navbar */
        left: 20px; /* Align to the left */
        z-index: 9998; /* Ensure the menu stays on top */
    }

    .mobile-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobile-menu ul li {
        padding: 10px 0;
    }

    .mobile-menu ul li a {
        color: #1A5DA1;
        text-decoration: none;
    }

    .mobile-menu ul li a:hover {
        color: #A6C8EB; /* Light blue color on hover */
    }
}
