.search-cont {
    width: 100%;
    height: 41px;
    background-color: white;
    border-radius: 20.5px;
    display: flex;
    align-items: center;
    border: 1px solid #A6C8EB;
    position: relative;
    color: black;
}

.search-cont .icon {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
}

.search-cont input {
    width: 100%;
    height: 41px;
    border-radius: 20.5px;
    background-color: transparent;
    border: none;
    padding: 0 10px;
    flex-grow: 1;
}

.search-cont input:focus {
    outline: none;
}

.suggestions {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 20.5px 20.5px;
    z-index: 1000;
}

.suggestion-item {
    padding: 10px 16px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.loading {
    text-align: center;
    padding: 10px;
}
