/* ProductGridSkeleton.css */

.product-grid-skeleton {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, minmax(250px, 1fr)); /* Adjust to match card grid layout */
  }
  
  .product-card-skeleton {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: pulse 1.5s infinite ease-in-out;
    height: 450px; /* Ensure this matches your actual card height */
  }
  
  @keyframes pulse {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .product-card-skeleton::before {
    content: '';
    display: block;
    width: 100%;
    height: 170px; /* Matches the card image height */
    background: #e0e0e0;
  }
  
  .product-card-skeleton::after {
    content: '';
    display: block;
    height: 80px; /* Matches the card header height */
    background: #e0e0e0;
  }
  